import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CONSTANTS } from '../../../core/constants/constants'
import { FlowGroup } from './flowGroup.component';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class FlowGroupService {

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) {

  }

  getFlowGroupListByInstance() {
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getFlowGroupListAll() {
    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}`, { observe: 'response' })
  }

  getFlowGroupList(page: string) {
    if (!page) {
      page = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1`
    }

    return this.httpClient
      .get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}${page}`, { observe: 'response' })
  }

  getFlowGroup(flowGroup: FlowGroup, page) {
    
    let filtroFinal
    if (page) {
      filtroFinal = page
    } else {
      filtroFinal = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1&Name=${(flowGroup['Name'] ? flowGroup.Name : '')}`
    }

    return this.httpClient.get<any>(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}${filtroFinal}`, { observe: 'response' });
  }

  updateFlowGroup(flowGroup: FlowGroup) {
    delete flowGroup.InstanceName
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}`, flowGroup, { observe: 'response' });
  }

  deleteFlowGroup(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}?Id=${Id}`, { observe: 'response' });
  }

  postflowGroup(flowGroup: FlowGroup) {
    delete flowGroup.Id
    delete flowGroup.InstanceName
    flowGroup.InstanceId = this.utilsService.getInstanceLoginId()   
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.FLOWGROUP}`, flowGroup, { observe: 'response' });
  }
}