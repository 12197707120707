import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'src/app/core/constants/constants';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from '../../../../environments/environment';


export interface InstanceFlowRefField {
  Id?: number,
  Name?: string,
  Code?: string,
  InstanceId?: number,
  InstanceFlowId?: string,
  InstanceFlowName?: string,
  TypeId?: number,
  TypeCode?: number,
  TypeName?: string,
  Active?: number,
  Value?: string
}

@Injectable({
  providedIn: 'root'
})
export class InstanceFlowReferenceFieldService {

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilsService
  ) { }

  

  getInstanceFlowRefFieldListByInstance() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}?InstanceId=${this.utilsService.getInstanceLoginId()}`, { observe: 'response' })
  }

  getInstanceFlowRefFieldListByInstanceByGroupByInstanceFlow(groups: any[], instanceFlows: any[]) {

    let filtro = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Active=1`
    if(groups.length){
      filtro += `&NotGroupIds=${groups.join(',')}`
    }

    if(instanceFlows.length){
      filtro += `&NotInstanceFlowIds=${instanceFlows.join(',')}`
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}${filtro}`, { observe: 'response' })
  }

  getInstanceFlowRefFieldListAll() {
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}`, { observe: 'response' })
  }

  getInstanceFlowRefFieldList(page: string) {
    if (!page) {
      page = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1`
    }

    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}${page}`, { observe: 'response' })
  }

  getInstanceFlowRefField(instanceFlowRefField: InstanceFlowRefField, page: any) {    
    let filtroFinal: string
    if (page) {
      filtroFinal = page
    } else {    
      
      filtroFinal = `?InstanceId=${this.utilsService.getInstanceLoginId()}&Page=1${instanceFlowRefField.Name ? '&Name='+ instanceFlowRefField.Name : ''}${instanceFlowRefField.InstanceFlowId ? '&InstanceFlowId=' + instanceFlowRefField.InstanceFlowId : ''}${instanceFlowRefField.Code ? '&Code=' + instanceFlowRefField.Code : ''}${instanceFlowRefField.TypeId ? '&TypeId=' + instanceFlowRefField.TypeId : ''}${instanceFlowRefField.Active != null ? '&Active=' + (instanceFlowRefField.Active) : '' }`
    }
    
    return this.httpClient.get(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}${filtroFinal}`, { observe: 'response' })
  }

  createFiltroGetInstanceFlowRefField(instanceFlowRefField: InstanceFlowRefField, page: string) {
    let filtroFinal: string
    if (page) {
      filtroFinal = page.replace('?', '&')
    } else {
      filtroFinal = `&Name=${(instanceFlowRefField.Name ? instanceFlowRefField.Name : '')}&InstanceFlowId=${(instanceFlowRefField.InstanceFlowId ? instanceFlowRefField.InstanceFlowId : '')}&Code=${(instanceFlowRefField.Code ? instanceFlowRefField.Code : '')}&TypeId=${(instanceFlowRefField.TypeId ? instanceFlowRefField.TypeId : '')}`
    }
    return filtroFinal

  }


  updateInstanceFlowRefField(instanceFlowRefField: InstanceFlowRefField) {
    let tempInstanceFlowRefField = this.deleteInstanceFlowRefFieldProperties(instanceFlowRefField, 'put')
    tempInstanceFlowRefField.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.put(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}`, tempInstanceFlowRefField, { observe: 'response' });
  }

  postInstanceFlowRefField(instanceFlowRefField: InstanceFlowRefField) {   
     
    let tempInstanceFlowRefField = this.deleteInstanceFlowRefFieldProperties(instanceFlowRefField, 'post')
    tempInstanceFlowRefField.InstanceId = this.utilsService.getInstanceLoginId()
    return this.httpClient.post(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}`, tempInstanceFlowRefField, { observe: 'response' });
  }

  deleteInstanceFlowRefField(Id: number) {
    return this.httpClient.delete(`${environment.apiUrl}${CONSTANTS.ENDPOINT.INSTANCEFLOWREFFIELD}?Id=${Id}`, { observe: 'response' });
  }


  private deleteInstanceFlowRefFieldProperties(instanceFlowRefField: InstanceFlowRefField, method: string): InstanceFlowRefField {
    if (method == 'post') {
      delete instanceFlowRefField.Id;
    }

    // delete instanceFlowRefField.InstanceId
    delete instanceFlowRefField.InstanceFlowName;
    delete instanceFlowRefField.TypeCode;
    delete instanceFlowRefField.TypeName

    return instanceFlowRefField
  }
}
