import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { VerificaPerfil } from '../../models/verificaPerfil';
import { Instance } from 'src/app/features/cadastros/instancia/instancia.component';
import { LoginResponse } from 'src/app/features/login/login.service';

const KEY_TOKEN = 'authToken';
const KEY_COOKIE = 'B1SESSION';
const KEY_TIMEOUT = 'SessionTimeout';
const KEY_USER = 'loggedUser';
const KEY_SEARCH = 'savedSearch';
const KEY_INSTANCE = 'instanceLogin'
const KEY_USER_INSTANCES = "userInstances"

@Injectable({ providedIn: 'root' })

export class StorageService {

    verificaPerfil = new VerificaPerfil();

    constructor(private cookieService: CookieService, private route: Router) { }

    /** @description retorna cabeçario com token de acessso
	* @param {filter} headers cabeçario de requisição
	*/
    createAuthHeader(headers: HttpHeaders) {
        headers = headers.append("Authorization", `Bearer ${this.getStorageToken()}`);
        return headers;
    };

    /** @description informa se possui token no localstorage
	*/
    hasToken() {
        return !!this.getStorageToken();
    };

    //////////////////////////// GRAVA ///////////////////////////////////
    /** @description grava token no localstorage
	* @param {token} token token de acesso.
	*/
    setStorageToken(token: any) {
        window.sessionStorage.setItem(KEY_TOKEN, token);
        //window.localStorage.setItem(KEY_TOKEN, token);       
    };

    /** @description grava cookie com token de acesso
	* @param {token} token token de acesso
	*/
    setCookieToken(token: any) {
        this.cookieService.set(KEY_COOKIE, token);
    };

    /////// STORAGE TOKEN  FIM
    /////// STORAGE USER INICIO
    /** @description grava dasdos do usuario logado no localstorage
	* @param {LoginResponse} loginResponse obj com infos do usuario.
	*/
    setStorageUser(loginResponse: LoginResponse) {
        // let user: User = {}
        // user.Name = loginResponse.UserName,
            // user.ProfileType = loginResponse.ProfileType
        window.sessionStorage.setItem(KEY_USER, JSON.stringify(loginResponse));
        //window.localStorage.setItem(KEY_USER, JSON.stringify(user));        
    }

    /////// STORAGE USER INICIO
    /** @description grava dasdos do usuario logado no localstorage
	* @param {user} user obj com infos do usuario.
	*/
    setStorageSaveSearch(data: any) {
        window.sessionStorage.setItem(KEY_SEARCH, JSON.stringify(data));
        //window.localStorage.setItem(KEY_USER, JSON.stringify(user));        
    }

    setStorageTimeout() {
        let storageTimeout = new Date();
        
        window.sessionStorage.setItem(KEY_TIMEOUT, JSON.stringify(storageTimeout));
      
    }
    ///////////////////////
    /** @description retorna o token gravado no local storage
	*/
    getStorageToken() {
      return window.sessionStorage.getItem(KEY_TOKEN);
    }

    /** @description retorna todas as infos do usuario gravadas no localstorage
	*/
    getStorageUserAllInfo() {
        //return window.localStorage.getItem(KEY_USER);
        return JSON.parse(window.sessionStorage.getItem(KEY_USER));
    };

    /** @description retorna todas as infos do usuario gravadas no localstorage
	*/
    getStorageSaveSearchs() {
        //return window.localStorage.getItem(KEY_USER);
        return JSON.parse(window.sessionStorage.getItem(KEY_SEARCH));
    };

    /** @description retorna dados de permissão do usaurio
	*/
    getStoragePermition() {
        //return window.localStorage.getItem(KEY_USER);
        let permicoes = JSON.parse(window.sessionStorage.getItem(KEY_USER));
        return permicoes.CONFIG_FR_LI_PERFIL;
    };

    /** @description retorna dados de configuração do usuario
	*/
    getStorageConfg() {
        //return window.localStorage.getItem(KEY_USER);
        let config = JSON.parse(window.sessionStorage.getItem(KEY_USER));
        return config.CONFIG_FR_LJ_GERAL;
    };

    /** @description retorna timeout da seção
	*/
    getStorageTimeout() {
        //return window.localStorage.getItem(KEY_USER);
        
        let config = JSON.parse(window.sessionStorage.getItem(KEY_TIMEOUT));
        
        return config;
    };

    /** @description retorna todas as infos do usuario no local storage
	*/
    getStorageUser() {
      
        //return window.localStorage.getItem(KEY_USER);
        let userData = JSON.parse(window.sessionStorage.getItem(KEY_USER));
        return userData
    };


    /** @description remove infos do usuario do localstorage
	*/
    deleteStorageUser() {
        window.sessionStorage.removeItem(KEY_USER);
    };

    /** @description remove apenar o token do local storage
	*/
    deletetorageToken() {
        window.sessionStorage.removeItem(KEY_TOKEN);
    };

    /** @description destroi dados do localstorage 
	*/
    logout() {
      sessionStorage.clear()
        // this.deletetorageToken();
        // this.deleteStorageUser();
        // this.deleteInstanceLogin();
        // this.deleteUserInstances();
        //this.removeStorageFilial();
        //this.removeStorageFilialNome();
        // redirecionar para raiz
        this.cookieService.deleteAll();
    };

    deleteInstanceLogin() {
        window.sessionStorage.removeItem(KEY_INSTANCE)
    }
    deleteUserInstances() {
        window.sessionStorage.removeItem(KEY_USER_INSTANCES)
    }


    // Dados da Instance que foi selecionado no login
    setInstanceLogin(instance: Instance) {
        window.sessionStorage.setItem(KEY_INSTANCE, JSON.stringify(instance))
    }

    getInstanceLogin(): Instance {
        return JSON.parse(window.sessionStorage.getItem(KEY_INSTANCE))
    }

    // Array de instancia que o usuário pode selecionar
    setUserInstances(instance: Instance[]) {
        window.sessionStorage.setItem(KEY_USER_INSTANCES, JSON.stringify(instance))
    }

    getUserInstances(): Instance[] {
        return JSON.parse(window.sessionStorage.getItem(KEY_USER_INSTANCES))
    }



    /////// storage filtro

}